import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import Login from '../views/Login.vue'
import GantiPassword from '../views/GantiPassword.vue'

import Admin from '../views/MainData/Admin.vue'
import AdminRole from '../views/MainData/AdminRole.vue'

import Buku from '../views/MasterData/Buku.vue'

import DetailBuku from '../views/PublicDetail/DetailBuku.vue'
import DetailTest from '../views/PublicDetail/DetailTest.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },{
    path: '/Uploads',
    name: 'Login',
    component: Login
  },
  {
    path: '/GantiPassword',
    name: 'GantiPassword',
    component: GantiPassword
  },
  {
    path: '/Dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  
  {
    path: '/Admin',
    name: 'Admin',
    component: Admin
  },
  {
    path: '/AdminRole',
    name: 'AdminRole',
    component: AdminRole
  }, 
 
  {
    path: '/Buku',
    name: 'Buku',
    component: Buku
  }, 
  
  {
    path: '/DetailBuku/:id',
    name: 'DetailBuku',
    component: DetailBuku
  }, 
  {
    path: '/DetailTest/:id',
    name: 'DetailTest',
    component: DetailTest
  }, 
]

const router = new VueRouter({
  // mode: 'history',
  routes
})

export default router
