<template>
  <div>
    <v-breadcrumbs
      class="breadcrumbsmain fontall"
      :items="itemsbr"
      large
      color="black"
      style="font-weight: bold"
      ><template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template></v-breadcrumbs
    >

    <v-data-table
      :headers="headers"
      :items="dataobject"
      :search="search"
      :loading="loadtable"
      loading-text="Loading... Please wait"
      class="rounded elevation-6 mx-3 pa-1 itemchild"
    >
      <template v-slot:item.role_name="{ item }">
        <v-chip label :color="getColorStatus(item.role_code)" dark>
          {{ item.role_name }}
        </v-chip>
      </template>

      <template v-slot:top>   
        <div class="mb-2 mt-1 mx-1" style="background-color:#d3dffd"><h2 class="pl-2 py-2">Daftar Admin</h2></div>     
        <v-divider class="mb-5 mt-1"></v-divider>
        <v-toolbar flat>
          <v-btn dark class="mb-2" @click="showAddModal()" color="primary">
            <v-icon class="mr-1" small>mdi-sticker-plus-outline</v-icon> Tambah
          </v-btn>
          <v-btn dark class="mb-2 ml-2" @click="showAddModal()" color="#25695c">
            <v-icon class="mr-1" small>mdi-download-circle-outline</v-icon> PDF
          </v-btn>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search..."
            single-line
            hide-details
          ></v-text-field>
          <!-- Modal Add Edit -->
          <v-dialog persistent v-model="dialog" max-width="600px">
            <v-card>
              <v-card-text class="fontall pb-0 px-2 pt-3">
                <div class="mb-2 mt-1 mx-1" style="background-color:#d3dffd"><h2 class="pl-2 py-2">{{titlemodal}}</h2></div> 
              </v-card-text>
              <v-divider class="mt-2 mb-3 mx-3"></v-divider>
              <v-card-text class="fontall pb-1">
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-container fluid>
                    <v-row>
                      <v-col cols="12" sm="3" md="3" class="pa-1" style="text-align: right;">
                        <label class="mr-2 mt-1" style="color: black;">Pilih Karyawan <span style="color: crimson;">*</span> :</label>
                      </v-col>
                      <v-col cols="12" sm="9" md="9" class="pa-1">
                        <v-select
                          v-model="defaultItem.kode_karyawan"
                          :items="itemsemp"
                          placeholder="Pilih Karyawan"
                          class="fontall"
                          item-text="nama_lengkap"
                          item-value="kode_karyawan"
                          outlined
                          color="#25695c"
                          dense
                          :disabled='isDisabled'
                          clearable
                          :rules="[(v) => !!v || 'Field is required']"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="3" md="3" class="pa-1" style="text-align: right;">
                        <label class="mr-2 mt-1" style="color: black;">Pilih Role Admin <span style="color: crimson;">*</span> :</label>
                      </v-col>
                      <v-col cols="12" sm="9" md="9" class="pa-1">
                        <v-select
                          v-model="defaultItem.role_code"
                          :items="itemsrole"
                          placeholder="Pilih Role Admin"
                          class="fontall"
                          item-text="name"
                          item-value="id"
                          outlined
                          color="#25695c"
                          dense
                          clearable
                          :rules="[(v) => !!v || 'Field is required']"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="3" md="3" class="pa-1" style="text-align: right;">
                        <label class="mr-2 mt-1" style="color: black;">Email <span style="color: crimson;">*</span> :</label>
                      </v-col>
                      <v-col cols="12" sm="9" md="9" class="pa-1">
                        <v-text-field
                          v-model="defaultItem.email"
                          outlined
                          placeholder="Email"
                          class="fontall"
                          color="#25695c"
                          dense
                          :rules="[rules.required, rules.email]"
                        ></v-text-field>
                      </v-col>  
                    </v-row>
                    <v-row>               
                      <v-col cols="12" sm="3" md="3" class="pa-1" style="text-align: right;">
                        <label class="mr-2 mt-1" style="color: black;">No Telp <span style="color: crimson;">*</span> :</label>
                      </v-col>
                      <v-col cols="12" sm="9" md="9" class="pa-1">
                        <v-text-field
                          v-model="defaultItem.no_telp"
                          outlined
                          placeholder="No Telp"
                          class="fontall"
                          color="#25695c"
                          dense
                          :rules="[(v) => !!v || 'Field is required']"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row  v-if="AddModal == true">
                      <v-col cols="12" sm="3" md="3" class="pa-1" style="text-align: right;">
                        <label class="mr-2 mt-1" style="color: black;">Password <span style="color: crimson;">*</span> :</label>
                      </v-col>
                      <v-col cols="12" sm="9" md="9" class="pa-1">
                        <v-text-field
                          v-model="defaultItem.password"
                          :rules="[rules.required, rules.countermin]"
                          outlined
                          placeholder="Password"
                          class="fontall"
                          color="#25695c"
                          dense
                        ></v-text-field>
                      </v-col> 
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-divider class="my-1 mx-3"></v-divider>
              <v-card-actions>
                <v-btn dark :loading="loading" color="#25695c" @click="save">
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon>
                  Simpan
                </v-btn>
                <v-btn dark color="#8b8787" @click="close">
                  <v-icon left> mdi-close-circle-outline </v-icon> Batal
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Modal Delete -->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >Apakah yakin menghapus admin ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn dark color="#25695c" @click="deleteItemConfirm">
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon>
                  OK</v-btn
                >
                <v-btn dark color="#8b8787" @click="closeDelete">
                  <v-icon left> mdi-close-circle-outline </v-icon>
                  Batal
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Modal Reset -->
          <v-dialog v-model="dialogReset" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >Apakah yakin akan mereset kata sandi?</v-card-title
              >
              <div style="text-align: center;">
              <h3>Password Default : 123456</h3></div>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn dark color="#25695c" @click="resetItemConfirm"
                  ><v-icon left> mdi-checkbox-marked-circle-outline </v-icon>
                  OK</v-btn
                >
                <v-btn dark color="#8b8787" @click="close">
                  <v-icon left> mdi-close-circle-outline </v-icon> Batal</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <!-- <v-icon class="mr-2" @click="detailItem(item)" color="primary">
          mdi-information
        </v-icon> -->
        <v-btn small class="mb-1 mt-1 mr-1" @click="showEditModal(item)" color="#1f8b24">
            <v-icon color="white">mdi-lead-pencil</v-icon>
        </v-btn>
        <v-btn small class="mb-1 mt-1 mr-1" @click="resetItem(item)" color="#bf9168">
            <v-icon  color="white">mdi-autorenew</v-icon>
        </v-btn>
        <v-btn small class="mb-1 mt-1 mr-1" @click="showDeleteModal(item)" color="#E20404">
            <v-icon  color="white">mdi-delete-outline</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-snackbar
      v-model="snackbar"
      :color="colorsnackbar"
      :timeout="timeoutsnackbar"
    >
      {{ textsnackbar }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import HelperGlobal from "../../services/Helper";
const HelperGlobalService = new HelperGlobal();

export default {
  name: "Admin",
  data: () => ({
    itemsbr: [
      {
        text: "Data Admin",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
      {
        text: "Admin",
        disabled: true,
        href: "breadcrumbs_link_1",
      },
    ],

    rules: {
      required: (value) => !!value || "Field is Required.",
      countermin: (value) => value.length > 5 || "Min 6 characters",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
    loading: false,
    titlemodal: 'Tambah Admin Baru',
    isDisabled : false,
    value: "add",
    dialog: false,
    dialogDelete: false,
    dialogReset: false,
    loadtable: false,
    search: "",
    authtoken: "",
    BaseUrlApi: "",
    headers: [
      { text: "Nama Admin", value: "nama", divider:true },
      { text: "No Telp", value: "no_telp", divider:true  },
      { text: "Email", value: "email", divider:true  },
      { text: "Role Admin", value: "role_name", divider:true  },
      { text: "Actions", value: "actions", sortable: false, width: "20%", divider:true  },
    ],
    dataobject: [],

    defaultItem: {
      user_id: "",
      nama: "",
      no_telp: "",
      alamat: "",
      pekerjaan: "",
      status_admin: "",
      password: "",
      role_code: "",
      email: "",
      role_name: "",
      kode_karyawan: "",
    },

    itemsrole: [],
    itemsemp: [],
    itemsstatus_admin: [
      { text: "Aktif", value: 0 },
      { text: "Tidak_Aktif", value: 1 },
    ],

    AddModal: true,
    valid: true,

    snackbar: false,
    textsnackbar: "Test",
    timeoutsnackbar: 3000,
    colorsnackbar: null,

    admin_id: "",
  }),

  created() {
    // this.authtoken = localStorage.getItem("token");
    // this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
  },
  beforeMount() {    
    this.authtoken = localStorage.getItem("token");
    // this.firstAccessPage();
    this.User = JSON.parse(localStorage.getItem("User"));
    if (this.User) {
      this.admin_id = this.User.code;
    }
    this.BaseUrl = HelperGlobalService.getBaseURL();
    this.BaseUrlApi = HelperGlobalService.getBaseURLApi();
    console.log('api',this.BaseUrlApi)
    this.initialize();
    this.getRole();
    this.getEmp();
  },

  methods: {
    async initialize() {
      this.loadtable = true;
      try {
        const response = await axios.get(this.BaseUrlApi + "GetAdmin", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.dataobject = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.dataobject = [];
        }
      this.loadtable = false;
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.dataobject = [];
        }
      this.loadtable = false;
      }
    },
    async getRole() {
      try {
        const response = await axios.get(this.BaseUrlApi + "GetRole", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.itemsrole = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.itemsrole = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.itemsrole = [];
        }
      }
    },
    async addApi(datapost) {
      try {
        const response = await axios.post(
          this.BaseUrlApi + "CreateUser",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        this.loading = false;
        if (response.data.data.result == "success") {
          this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses menambahkan data";
          this.initialize();
        } else {
          this.dialog = true;
        }
      } catch (error) {
        console.error(error);
        this.loading = false;
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        }
      }
    },
    async editApi(datapost) {
      console.log(datapost);
      try {
        const response = await axios.put(
          this.BaseUrlApi + "EditUser/"+this.defaultItem.user_id,
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        this.loading = false;
        if (response.data.data.result == "success") {
          this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses mengubah data";
          this.initialize();
        } else {
          this.dialog = true;
        }
      } catch (error) {
        console.error(error);
        this.loading = false;
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        }
      }
    },
    async deleteApi() {
      const datapost = {
        user_id: this.defaultItem.user_id,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.delete(
          this.BaseUrlApi + "DeleteUser/"+this.defaultItem.user_id,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses menghapus data";

          this.dialogDelete = false;
          this.initialize();
        } else {
          this.dialog = true;
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        }
      }
    },
    async resetItemConfirm() {
      console.log(this.defaultItem);
      const datapost = {
        user_id: this.defaultItem.user_id,
        email: this.defaultItem.email,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.put(
          this.BaseUrlApi + "ResetPasswordUser/"+this.defaultItem.user_id,
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialogReset = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses mereset password";
          this.initialize();
        } else {
          this.dialogReset = true;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal mengubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          this.dialogReset = true;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal mengubah data";
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.dialogReset = true;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal mengubah data";
        }
      }
    },

    showAddModal() {
      this.defaultItem.user_id = "";
      this.defaultItem.nama = "";
      this.defaultItem.no_telp = "";
      this.defaultItem.kode_karyawan = "";
      this.defaultItem.status_admin = "";
      this.defaultItem.role_code = "";
      this.defaultItem.email = "";
      this.defaultItem.role_name = "";
      this.titlemodal = "Tambah Admin Baru";
      // console.log();
      console.log(this.$refs.form);
      if(this.$refs.form){
        this.$refs.form.resetValidation();
      }
      this.AddModal = true;
      this.isDisabled = false;
      this.dialog = true;
    },
    showEditModal(item) {
      this.defaultItem = Object.assign({}, item);
      console.log(item);
      this.defaultItem.role_code = parseInt(item.role_code);
      this.titlemodal = "Ubah Data Admin";
      console.log(this.defaultItem);
      this.AddModal = false;
      this.isDisabled = true;
      this.dialog = true;
    },
    showDeleteModal(item) {
      this.defaultItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    close() {
      this.dialog = false;
      this.dialogReset = false;
    },
    closeDelete() {
      this.dialogDelete = false;
    },

    save() {
      this.loading = true;

      const datapost = {
        kode_karyawan: this.defaultItem.kode_karyawan,
        role_code: this.defaultItem.role_code,
        no_telp: this.defaultItem.no_telp,
        email: this.defaultItem.email,
        password: "default",
        // status_admin: "default",
        // user_id: "default",
        // alamat: this.defaultItem.alamat,
      };

      if (HelperGlobalService.checkMandatory(datapost, "object") == true) {
        if (this.defaultItem.user_id) {
          console.log("Save Edit");
          // datapost.user_id= this.defaultItem.user_id,
          // datapost.status_admin= this.defaultItem.status_admin,
          this.editApi(datapost);
        } else {
          console.log("Save Add");
          datapost.password= this.defaultItem.password,
          this.addApi(datapost);
        }
        this.loading = false;
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar =
          "Gagal Simpan, Kolom required (*) tidak boleh ada yang kosong";
        this.loading = false;
      }
    },

    deleteItemConfirm() {
      this.deleteApi();
    },

    resetItem(item) {
      this.defaultItem.user_id = item.user_id;
      this.defaultItem.email = item.email;
      this.dialogReset = true;
    },

    getColorStatus(role_code) {
      console.log(role_code);
      if (role_code == 0) return "#25695C";
      else if (role_code == 1) return "#25695C";
      else if (role_code == 2) return "#BF9168";
      else if (role_code == 3) return "#D42F2F";
      else return "#9CACA3";
    },
  },
};
</script>
<style>
table {
  border: 1px solid;
  border-color: lightgrey;
}

.v-data-table-header{
  background-color: #d3dffd;
}

input[required] + label:after {
	content: '*';
	color: red;
  }
</style>
