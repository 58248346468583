<template>
  <v-container class="mt-1 mx-1">
    <v-row style="margin-top: -15px">
      <v-col cols="12" md="12">
        <!-- <v-card>
          <v-alert show dismissible text color="#444941" class="fontall">
            Login Berhasil! Selamat datang di <strong>HRIS</strong>
          </v-alert>
        </v-card> -->
        <!-- <div>
          <v-alert
            type="success"
            border="left"
            color="#4199bb"
            dark
            class="fontall"
            dismissible
          >
            Login Berhasil! Selamat datang di Admin Management System
          </v-alert>
        </div> -->

        <v-card>
          <v-card-title>Good {{ greeting }}, {{ fullnameadmin }}</v-card-title>
          <v-card-subtitle> it's {{ day }}, {{ date }} </v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="3" class="d-flex align-end">
                <v-avatar size="50" rounded class="elevation-1">
                  <v-icon
                    dark
                    style="font-size: 35px !important"
                    color="#32c351"
                    >mdi-chart-line
                  </v-icon>
                </v-avatar>
                <div class="ms-3">
                  <p class="itemparent mb-0">
                    {{ data1.Judul }}
                  </p>
                  <h3 class="fontall">
                    {{ getRupiah(data1.Count) }}
                  </h3>
                </div>
              </v-col>

              <v-col cols="12" md="3" class="d-flex align-end">
                <v-avatar size="50" rounded class="elevation-1">
                  <v-icon style="font-size: 35px !important" color="#f13535"
                    >mdi-chart-bell-curve</v-icon
                  >
                </v-avatar>
                <div class="ms-3">
                  <p class="itemparent mb-0">
                    {{ data2.Judul }}
                  </p>
                  <h3 class="fontall">
                    {{ getRupiah(data2.Count) }}
                  </h3>
                </div>
              </v-col>

              <v-col cols="12" md="3" class="d-flex align-end">
                <v-avatar size="50" rounded class="elevation-1">
                  <v-icon style="font-size: 35px !important" color="#444941"
                    >mdi-account-multiple</v-icon
                  >
                </v-avatar>
                <div class="ms-3">
                  <p class="itemparent mb-0">
                    {{ data3.Judul }}
                  </p>
                  <h3 class="fontall">
                    {{ data3.Count }}
                  </h3>
                </div>
              </v-col>
              <v-col cols="3" class="d-none d-md-block">
                <v-row align="center" justify="center" class="mb-0">
                  <v-img
                    max-width="200"
                    src="/images/dashboard_logo.jpg"
                    style="margin-top: -50px"
                  ></v-img>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense class="mt-3">
      <v-col class="px-1 pt-2 pb-1 mb-3" cols="12" sm="4" md="4">
        <v-card class="fontall pa-md-4" elevation="5">
          <!-- <header class="fontall pa-2 mb-3" style="text-align: center">
            Pending Task
          </header> -->
          <!-- <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Name</th>
                  <th class="text-left">Email</th>
                  <th class="text-left">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in profils" :key="item.name">
                  <td>{{ item.name }}</td>
                  <td>{{ item.email }}</td>
                  <td>
                    <b-badge
                      :variant="
                        item.status === 'Pending'
                          ? 'success'
                          : item.status === 'Declined'
                          ? 'danger'
                          : 'info'
                      "
                      pill
                    >
                      {{ item.status }}
                    </b-badge>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table> -->
          <v-data-table
            :headers="headers"
            :items="profils"
            :items-per-page="3"
            hide-items-per-page
          >
            <template v-slot:item.totaluang="{ item }">
              {{ getRupiah(item.totaluang) }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col class="px-1 py-1 mt-1" cols="12" sm="4" md="4">
        <v-card class="d-none d-md-block" elevation="5">
          <v-card-text class="py-1 px-1">
            <GChart
              type="ColumnChart"
              :data="chartDataColom"
              :options="colomchartOptionsGoogle"
            />
          </v-card-text>
          <v-card-text class="py-0 px-1">
            <div class="text-h6 font-weight-light mb-2">
              <v-btn
                @click="showPenjualan3bulandetail()"
                block
                text
                small
                class="grey--text"
                >View Detail</v-btn
              >
            </div>
          </v-card-text>
        </v-card>

        <v-card class="d-sm-block d-md-none" elevation="5">
          <v-card-text class="py-1 px-1">
            <GChart
              type="ColumnChart"
              :data="chartDataColom"
              :options="colomchartOptionsGoogleMobile"
            />
          </v-card-text>
          <v-card-text class="py-0 px-1">
            <div class="text-h6 font-weight-light mb-2">
              <v-btn
                @click="showPenjualan3bulandetail()"
                block
                text
                small
                class="grey--text"
                >View Detail</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="px-1 py-1 mt-1" cols="12" sm="4" md="4">
        <v-card class="d-none d-md-block" elevation="5">
          <v-card-text class="py-1 px-1">
            <GChart
              type="PieChart"
              :data="chartData"
              :options="chartOptionsGoogle"
            />
          </v-card-text>
          <v-card-text class="py-0 px-1">
            <div class="text-h6 font-weight-light mb-2">
              <v-btn
                @click="showPenjualanSalesDetail()"
                block
                text
                small
                class="grey--text"
                >View Detail</v-btn
              >
            </div>
          </v-card-text>
        </v-card>

        <v-card class="d-sm-block d-md-none" elevation="5">
          <v-card-text class="py-1 px-1">
            <GChart
              type="PieChart"
              :data="chartData"
              :options="chartOptionsGoogleMobile"
            />
          </v-card-text>
          <v-card-text class="py-0 px-1">
            <div class="text-h6 font-weight-light mb-2">
              <v-btn
                @click="showPenjualanSalesDetail()"
                block
                text
                small
                class="grey--text"
                >View Detail</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogDetailSale" max-width="800px">
      <v-card>
        <v-card-title class="mb-1 headermodalstyle">
          <span>Detail Penjualan 3 Bulan</span>
        </v-card-title>
        <div style="text-align: center">
          <v-btn
            color="black"
            elevation="1"
            outlined
            @click="downloadpenjualan()"
          >
            <v-icon left> mdi-download-circle-outline </v-icon>
            Download
          </v-btn>
        </div>
        <v-card-text class="fontall pa-1">
          <v-container class="pa-1">
            <v-data-table
              :headers="headersPenjualan"
              :items="itemssale"
              class="rounded elevation-1 mx-1 pa-1 my-2"
              hide-default-footer
              disable-pagination
            >
              <template v-slot:item.totaluanginv="{ item }">
                {{ getRupiah(item.totaluanginv) }}
              </template>
              <template v-slot:item.totaluang="{ item }">
                {{ getRupiah(item.totaluang) }}
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDetailSales" max-width="800px">
      <v-card>
        <v-card-title class="mb-1 headermodalstyle">
          <span>Detail Penjualan Sales</span>
        </v-card-title>
        <div style="text-align: center">
          <v-btn
            color="black"
            elevation="1"
            outlined
            @click="downloadpenjualansales()"
          >
            <v-icon left> mdi-download-circle-outline </v-icon>
            Download
          </v-btn>
        </div>
        <v-divider class="mx-4 mt-3"></v-divider>
        <v-card-text class="fontall pa-1">
          <v-container class="pa-1">
            <div style="text-align: center">
              <h4>
                Data Penjualan {{ from_date_sales }} - {{ to_date_sales }}
              </h4>
            </div>
            <v-divider class="mx-2 mt-3"></v-divider>
            <v-data-table
              :headers="headersPenjualanSales"
              :items="itemssales"
              class="rounded elevation-1 mx-1 pa-1 my-2"
              hide-default-footer
              disable-pagination
            >
              <template v-slot:item.totaluangsales="{ item }">
                {{ getRupiah(item.totaluangsales) }}
              </template>
              <template v-slot:item.totaluang="{ item }">
                {{ getRupiah(item.totaluang) }}
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";

import VueApexCharts from "vue-apexcharts";

import HelperGlobal from "../services/Helper";
const HelperGlobalService = new HelperGlobal();

const gradients = [
  ["#222"],
  ["#42b3f4"],
  ["red", "orange", "black"],
  ["purple", "violet"],
  ["#FF0", "#F0F", "#00c6ff"],
  ["#1feaea", "#ffd200", "#f72047"],
];

export default {
  name: "Dashboard",

  components: {
    apexchart: VueApexCharts,
  },
  data: () => ({
    greeting: "Morning",
    date: "01 December 2021",
    day: "Sunday",

    drawer: null,
    BaseUrlGet: "",
    authtoken: "",

    dialogDetailSales: false,
    dialogDetailSale: false,

    itemssale: [],
    itemssales: [],
    from_date_sales: "",
    to_date_sales: "",

    headersPenjualan: [
      { text: "Bulan", value: "bulan" },
      { text: "SO Terbuat", value: "totalso" },
      { text: "Invoice Terbuat", value: "totalinv" },
      { text: "Barang Terjual", value: "totalbarang" },
      { text: "Total Uang Invoice", value: "totaluanginv" },
      { text: "Uang Terbayar", value: "totaluang" },
    ],
    headersPenjualanSales: [
      { text: "Sales", value: "nama" },
      { text: "SO Terbuat", value: "totalso" },
      { text: "Invoice Terbuat", value: "totalinv" },
      { text: "Barang Terjual", value: "totalbarang" },
      { text: "Total Uang Invoice", value: "totaluangsales" },
      { text: "Uang Terbayar", value: "totaluang" },
    ],

    headers: [
      {
        text: "Customer",
        align: "start",
        sortable: false,
        value: "nama_customer",
      },
      { text: "Total Beli", value: "totaluang" },
    ],
    profils: [
      {
        code_customer: "Admin1",
        nama_customer: "admin1@mail.com",
        total: "1",
        totalbarang: "1",
      },
      {
        code_customer: "Admin2",
        nama_customer: "admin2@mail.com",
        total: "1",
        totalbarang: "1",
      },
      {
        code_customer: "Cust1",
        nama_customer: "cust1@mail.com",
        total: "1",
        totalbarang: "1",
      },
    ],
    defaultItem: {
      code: "",
      nama: "",
      email: "",
      status: "",
      status_name: "",
    },

    data1: {
      Judul: "Total Uang Masuk",
      Count: "1",
      link: "/",
    },
    data2: {
      Judul: "Total Uang Keluar",
      Count: "11",
      link: "/",
    },
    data3: {
      Judul: "Total Customer",
      Count: "111",
      link: "/",
    },

    gradientDirection: "top",
    gradients,
    gradient: gradients[10],
    labelsSparkline: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "August",
      "Sept",
      "Okt",
      "Nop",
      "Dec",
    ],
    valueSparkline: [350, 200, 410, 390, 310, 460, 250, 240, 0, 0, 0, 0],
    srcimage: "https://cdn.vuetifyjs.com/images/cards/foster.jpg",
    datacollection: null,
    namaAdmin: "Nama Admin",

    chartDataColom: [
      ["Tempat", "Total"],
      ["Bulan1", 1000],
      ["Bulan2", 1170],
      ["Bulan3", 760],
    ],
    chartData: [
      ["Tempat", "Total"],
      ["Lokasi1", 1000],
      ["Lokasi2", 1170],
      ["Lokasi3", 760],
      ["Lokasi4", 1030],
      ["Lokasi5", 600],
    ],
    colomchartOptionsGoogle: {
      title: "Penjualan 3 Bulan",
      // pieHole: 0.4,
      // is3D: true,
      legend: "none",
      pieSliceText: "label",
      width: $(window).width() * 0.25,
      height: $(window).height() * 0.45,
    },
    colomchartOptionsGoogleMobile: {
      title: "Penjualan 3 Bulan ",
      // pieHole: 0.4,
      // is3D: true,
      legend: "none",
      pieSliceText: "label",
    },
    chartOptionsGoogle: {
      title: "Penjualan Sales Bulan ini",
      pieHole: 0.4,
      // is3D: true,
      // legend: "none",
      pieSliceText: "label",
      legend: { position: "bottom", alignment: "start" },
      width: $(window).width() * 0.25,
      height: $(window).height() * 0.45,
    },
    chartOptionsGoogleMobile: {
      title: "Penjualan Sales Bulan ini",
      pieHole: 0.4,
      // is3D: true,
      // legend: "none",
      pieSliceText: "label",
      legend: { position: "bottom", alignment: "start" },
    },

    fullnameadmin: "",
    nameadmin: "",
  }),
  created() {
    this.authtoken = localStorage.getItem("token");
    this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
    this.User = JSON.parse(localStorage.getItem("User"));
    if (this.User) {
      var name = this.User.name;
      this.fullnameadmin = name;
      const arrayname = name.split(" ");
      this.nameadmin = arrayname[0];
    }
    this.initialize();
  },
  methods: {
    async initialize() {
      this.loadtable = true;
      try {
        const response = await axios.get(this.BaseUrlGet + "Dashboard", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.loadtable = false;
          this.greeting = response.data.data.result.greeting;
          this.day = response.data.data.result.day;
          this.date = response.data.data.result.dateformat;
          this.data1.Count = response.data.data.result.Getpemasukantotal;
          this.data2.Count = response.data.data.result.Getpengeluarantotal;
          this.data3.Count = response.data.data.result.Gettotalcust;
          this.chartDataColom = response.data.data.result.listarrayinvtotal;
          this.chartData = response.data.data.result.listarraysales;
          this.profils = response.data.data.result.listvalcust;
        } else {
          console.log("Kosong");
          this.loadtable = false;
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          this.loadtable = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.loadtable = false;
          this.dataobject = [];
        }
      }
    },

    async getdetailsale() {
      try {
        const response = await axios.get(
          this.BaseUrlGet + "DashboardDetailSale",
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.itemssale = response.data.data.result;
        } else {
          console.log("Kosong");
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.dataobject = [];
        }
      }
    },

    async getdetailsales() {
      try {
        const response = await axios.get(
          this.BaseUrlGet + "DashboardDetailSales",
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.itemssales = response.data.data.result.list;
          this.from_date_sales = response.data.data.result.from_date;
          this.to_date_sales = response.data.data.result.to_date;
        } else {
          console.log("Kosong");
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.dataobject = [];
        }
      }
    },

    showPenjualan3bulandetail() {
      this.getdetailsale();
      this.dialogDetailSale = true;
    },
    showPenjualanSalesDetail() {
      this.getdetailsales();
      this.dialogDetailSales = true;
    },

    downloadpenjualan() {
      this.dialogDetailSale = false;
      var str = this.BaseUrlGet;
      window.open(
        str.substring(0, str.length - 4) + "DashboardDownloadDetailSale"
      );
    },
    downloadpenjualansales() {
      this.dialogDetailSales = false;
      var str = this.BaseUrlGet;
      window.open(
        str.substring(0, str.length - 4) + "DashboardDownloadDetailSales"
      );
    },

    getRupiah(val) {
      return HelperGlobalService.getRupiah(val);
    },
  },
};
</script>

