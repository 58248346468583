<template>
  <div style="font-family: cursive;">
    <v-app-bar
      app
      flat
    >
      <v-app-bar-nav-icon
        class="hidden-md-and-up"
      />

      <v-container class="mx-auto py-0">
        <v-row align="center">
          <v-img
            :src="require('@/assets/aqwamlogo.png')"
            class="mr-5"
            contain
            height="75"
            width="130"
            max-width="130"
            @click="$vuetify.goTo(0)"
          />

          <v-spacer />
          <v-btn       
            class="hidden-sm-and-down"
            text
            @click="scroll('home')"
          >
            Home
          </v-btn>
          <!-- <v-btn       
            class="hidden-sm-and-down"
            text
            @click="scroll('detail')"
          >
            Detail
          </v-btn>
          <v-btn       
            class="hidden-sm-and-down"
            text
            @click="scroll('image')"
          >
            Image
          </v-btn> -->
          <v-btn       
            class="hidden-sm-and-down"
            text
            @click="scroll('contact')"
          >
            Contact Us
          </v-btn>
        </v-row>
      </v-container>
    </v-app-bar>    

    <!-- <v-container
      id="home"
      tag="section"
    >
      <v-row>
        <v-col class="pb-0" cols="12" sm="6" md="6">{{defaultItem.nama_buku}}</v-col>
        <v-col class="pb-0" cols="12" sm="6" md="6"><img  max-height="520px" contain v-bind:src="fotoShow" alt=""></v-col>
      </v-row>      
    </v-container>

    <v-container
      id="about"
      tag="section"
    >
      <base-subheading>About Us</base-subheading>

      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ut tortor tellus. Donec maximus curs us pulvinar.
        Proin vehicula eros mauris, sit amet rutrum libero vestibulum ac. Morbi ullamcorper eu lacus vulputate consect.
        Etur vivamus sagittis accumsan quam eu malesuada. Proin et ante vel libero ultrices cursus. Donec felis leo, cu
        rsus eget hendrerit at, congue nec enim.
      </p>
    </v-container> -->

    <v-container v-if="valueIsi==true" id="home" tag="section" class="mt-5" style="text-align: -webkit-center;">
      <v-row>
          <v-col class="pa-2" cols="12" sm="8" md="8">            
              <div style="height: 550px;border-style: solid; border-color: #f36060;border-radius: 15px;"  class="pa-1">
              <v-row class="pa-1 hidden-sm-and-down">
                <v-col  cols="12" sm="3" md="3">
                  <v-flex md12 v-for="item in itemsGaleri" :key="item.id">
                    <div style="text-align-last: center;height: 95px;" class="pa-1 ma-1 d-flex flex-column justify-space-between align-center">
                      <v-img v-bind:src="item.foto" contain height="95%" @click="showImageClick(item.foto)"></v-img>
                    </div>        
                  </v-flex>
                </v-col>
                <v-col class="pa-2" cols="12" sm="9" md="9" style="height: 530px"> 
                  <div class="d-flex flex-column justify-space-between align-center" style="height: 100%">
                    <v-img
                      max-height="520px"
                      contain
                      v-bind:src="fotoShow"
                    ></v-img>
                  </div>
                </v-col>
              </v-row>
              <v-row class="pa-1 hidden-md-and-up">
                <v-col class="pa-2" cols="12" sm="9" md="9" style="height: 400px"> 
                  <div class="d-flex flex-column justify-space-between align-center" style="height: 100%">
                    <v-img
                      max-height="400px"
                      contain
                      v-bind:src="fotoShow"
                    ></v-img>
                  </div>
                </v-col>
                <v-col  cols="3" sm="3" md="3" style="display: flex;">
                  <v-flex md2 v-for="item in itemsGaleri" :key="item.id">
                    <div style="text-align-last: center;height: 95px;" class="pa-1 ma-1 d-flex flex-column justify-space-between align-center">
                      <v-img v-bind:src="item.foto" contain height="95%" @click="showImageClick(item.foto)"></v-img>
                    </div>        
                  </v-flex>
                </v-col>
                <v-col></v-col>
              </v-row>
              </div>
          </v-col>
          <v-col class="pa-2" cols="12" sm="4" md="4">
              <div style="height: 550px; text-align: left;border-style: solid; border-color: #f36060;border-radius: 15px; " class="pa-7">
                <v-row class="mb-2">
                  <v-col cols="12">
                    <div style=" text-align: center"><h3 style="font-weight:800">{{defaultItem.nama_buku}}</h3></div>                    
                  </v-col>
                </v-row>
                <v-row class="mb-2">
                  <v-col class="pt-1 pb-0" cols="12">
                    <h4 style="font-weight: 500;">Penerbit</h4>
                  </v-col>
                  <v-col class="py-1" cols="12">
                    <h4 class="pl-3" style="font-weight: 800;">{{defaultItem.penerbit}}</h4>
                  </v-col>
                </v-row>
                <v-row class="mb-2">
                  <v-col class="pt-1 pb-0" cols="12">
                    <h4 style="font-weight: 500;">Penulis</h4>
                  </v-col>
                  <v-col class="py-1" cols="12">
                    <h4 class="pl-3" style="font-weight: 800;">{{defaultItem.penulis}}</h4>
                  </v-col>
                </v-row>
                <v-row class="mb-2">
                  <v-col class="pt-1 pb-0" cols="12">
                    <h4 style="font-weight: 500;">ISBN</h4>
                  </v-col>
                  <v-col class="py-1" cols="12">
                    <h4 class="pl-3" style="font-weight: 800;">{{defaultItem.isbn}}</h4>
                  </v-col>
                </v-row>
                <v-row class="mb-2">
                  <v-col class="pt-1 pb-0" cols="12">
                    <h4 style="font-weight: 500;">Jenis Cover</h4>
                  </v-col>
                  <v-col class="py-1" cols="12">
                    <h4 class="pl-3" style="font-weight: 800;">{{defaultItem.jenis_cover}}</h4>
                  </v-col>
                </v-row>
                <v-row class="mb-2">
                  <v-col class="pt-1 pb-0" cols="12">
                    <h4 style="font-weight: 500;">Ukuran</h4>
                  </v-col>
                  <v-col class="py-1" cols="12">
                    <h4 class="pl-3" style="font-weight: 800;">{{defaultItem.ukuran}}</h4>
                  </v-col>
                </v-row>
                <v-row class="mb-2">
                  <v-col class="pt-1 pb-0" cols="12">
                    <h4 style="font-weight: 500;">Berat (gr) | Halaman</h4>
                  </v-col>
                  <v-col class="py-1" cols="12">
                    <h4 class="pl-3" style="font-weight: 800;">{{defaultItem.berat}} | {{defaultItem.halaman}}</h4>
                  </v-col>
                </v-row>
              </div>
          </v-col>
      </v-row>
      <v-row>
          <v-col class="pa-2" cols="12" sm="12" md="12">
              <div style=";border-style: solid; border-color: #f36060;border-radius: 15px;">
                <v-row class="mb-5 mt-3 mx-2">                  
                  <v-divider
                      class="mx-3 mt-3"
                      style="border-color: black"
                  ></v-divider>
                    <h5 style="color: black; font-size: 15px">
                      Deskripsi Buku
                    </h5>
                  <v-divider
                      class="mx-3 mt-3"
                      style="border-color: black"
                  ></v-divider>
                </v-row>
                <v-row class="mb-5 mt-3 mx-2">   
                  <p style="text-align: left;margin-left: 20px;margin-right: 20px;font-size: 15px;">
                    <span v-html="defaultItem.deskripsi"></span>
                  </p>
                </v-row>
              </div>
          </v-col>
      </v-row>
    </v-container>

    <v-container v-else id="home" tag="section" class="mt-5" style="text-align: -webkit-center; height:550px !important">
      <v-row class="mt-7">
        <v-spacer />
        <div style="text-align:center">
          <h1>404</h1>
          <h3>Not Found</h3>
        </div>
        <v-spacer />
      </v-row>
    </v-container>

    <v-container id="contact" tag="section" class="mt-1" style="text-align: -webkit-center;">
      <v-row>
          <v-col class="pa-2" cols="12" sm="12" md="12">
              <div>
                <v-row class="mb-2 mt-7 mx-2">                 
                  <v-divider
                      class="mx-3 mt-3"
                      style="border-color: #f36060"
                  ></v-divider>
                    <h5 style="color: #f36060; font-size: 20px">
                      Imprint
                    </h5>                 
                  <v-divider
                      class="mx-3 mt-3"
                      style="border-color: #f36060"
                  ></v-divider>
                </v-row>
              </div>
          </v-col>
      </v-row>
      <v-row style="margin-top:0px !important" class="mb-3">
          <v-col class="pa-2" cols="12" sm="3" md="3">
              <div>
                <v-img
                  :src="require('@/assets/aqwam_imprint_1.png')"
                  class="mr-5 ml-3"
                  contain
                  height="90"
                  width="170"
                  max-width="170"
                />
              </div>
          </v-col>
          <v-col class="pa-2" cols="12" sm="3" md="3">
              <div>
                <v-img
                  :src="require('@/assets/aqwam_imprint_2.png')"
                  class="mr-5 ml-3"
                  contain
                  height="90"
                  width="170"
                  max-width="170"
                />
              </div>
          </v-col>
          <v-col class="pa-2" cols="12" sm="3" md="3">
              <div>
                <v-img
                  :src="require('@/assets/aqwam_imprint_3.png')"
                  class="mr-5 ml-3"
                  contain
                  height="90"
                  width="170"
                  max-width="170"
                />
              </div>
          </v-col>
          <v-col class="pa-2" cols="12" sm="3" md="3">
              <div>
                <v-img
                  :src="require('@/assets/aqwam_imprint_4.png')"
                  class="mr-5 ml-3"
                  contain
                  height="90"
                  width="170"
                  max-width="170"
                />
              </div>
          </v-col>
      </v-row>
      <v-row style="margin-top:0px !important" class="mb-3">
          <v-col class="pa-2" cols="12" sm="4" md="4">
              <div>
                <v-img
                  :src="require('@/assets/aqwam_imprint_5.png')"
                  class="mr-5 ml-3"
                  contain
                  height="90"
                  width="170"
                  max-width="170"
                />
              </div>
          </v-col>
          <v-col class="pa-2" cols="12" sm="4" md="4">
              <div>
                <v-img
                  :src="require('@/assets/aqwam_imprint_6.png')"
                  class="mr-5 ml-3"
                  contain
                  height="90"
                  width="170"
                  max-width="170"
                />
              </div>
          </v-col>
          <v-col class="pa-2" cols="12" sm="4" md="4">
              <div>
                <v-img
                  :src="require('@/assets/aqwam_imprint_7.png')"
                  class="mr-5 ml-3"
                  contain
                  height="110"
                  width="170"
                  max-width="170"
                />
              </div>
          </v-col>
      </v-row>
    </v-container>

    <v-container id="contact" tag="section" class="mt-1" style="text-align: -webkit-center;">
      <v-row>
          <v-col class="pa-2" cols="12" sm="12" md="12">
              <div>
                <v-row class="mb-2 mt-7 mx-2">                 
                  <v-divider
                      class="mx-3 mt-3"
                      style="border-color: #f36060"
                  ></v-divider>
                    <h5 style="color: #f36060; font-size: 20px">
                      Contact Us
                    </h5>                 
                  <v-divider
                      class="mx-3 mt-3"
                      style="border-color: #f36060"
                  ></v-divider>
                </v-row>
              </div>
          </v-col>
      </v-row>
      <v-row style="margin-top:0px !important" class="mb-3">
          <v-col class="pa-2" cols="12" sm="4" md="4">
              <div>
                <v-img
                  :src="require('@/assets/aqwam_putih.png')"
                  class="mr-5 ml-3"
                  contain
                  height="150"
                  width="230"
                  max-width="230"
                />
                <h5>Membantu Anda Belajar Islam</h5>
              </div>
          </v-col>
          <v-col style="align-self: center;" class="px-7" cols="12" sm="4" md="4">
              <div>
                <h3 style="color: red;">Kantor Pusat (Solo)</h3> 
              </div>
              <div>
                <h4>Jl. Menco Raya 112, Gonilan, Kartasura - Solo 57169 <br/> (0271) 765 3000</h4>
              </div>
          </v-col>
          <v-col style="align-self: center;" class="px-7" cols="12" sm="4" md="4">
              <div>
                <h3 style="color: red;">Kantor Cabang (Jakarta)</h3> 
              </div>
              <div>
                <h4>Jl. Pondok Ranggon No. 17 RT. 002/RW. 006, Kec. Cipayung, Jakarta Timur 13860 <br/> (021) 845 94931</h4>
              </div>
          </v-col>
      </v-row>
    </v-container>

    <v-footer
      v-bind="localAttrs"
      class="px-0 mt-2"
    >
    <v-card
        flat
        tile
        width="100%"
        class="text-center px-0"
        style="background-color: #e3e3e3"
      >
        <v-divider></v-divider>

        <v-card-text>
          © 2020 Dev Aqwam.
        </v-card-text>
      </v-card>
    </v-footer>
  </div>
</template>

<script>
import axios from "axios";
import HelperGlobal from "../../services/Helper";
const HelperGlobalService = new HelperGlobal();

export default {
  name: "DetailBuku",
  data: () => ({
    authtoken: "",
    BaseUrlApi: "",
    BaseUrl:'',

    User:{},
    admin_id:'',
    email:'',
    namauser:'',
    user_id:'',

    defaultItem: {
      id_buku: "",
      barcode: "",
      nama_buku: "",
      deskripsi: "",
      penerbit: "",
      penulis: "",
      isbn: "",
      jenis_cover: "",
      ukuran: "",
      berat: "",
      halaman:"",
      link:"",
    },

    valueIsi:false,

    itemsGaleri:[],

    imageShowPush:'',
    fotoShow: '/images/noimage.png',
  }),

  beforeMount() {    
    this.authtoken = localStorage.getItem("token");
    this.User = JSON.parse(localStorage.getItem("User"));
    if (this.User) {
      this.admin_id = this.User.code;
      this.email = this.User.email;
      this.namauser = this.User.name;
      this.user_id = this.User.user_id;
    }
    this.BaseUrl = HelperGlobalService.getBaseURL();
    this.BaseUrlApi = HelperGlobalService.getBaseURLApi();
    //  console.log('The id is: ' + this.$route.params.id);
     this.defaultItem.nama_buku = this.$route.params.id.split('-').join(' ');
    // console.log('test--------',this.defaultItem.nama_buku);
    this.getDetail();
  },

  methods: {    
    async getDetail() {
      try {
        const response = await axios.get(this.BaseUrlApi + "Detail/"+this.defaultItem.nama_buku);
        console.log(response.data.data);
        if (response.data.length != 0) {
          this.valueIsi = true;
          this.defaultItem = Object.assign({},response.data.data.result.data);

          this.itemsGaleri = [];
          if(response.data.data.result.productImage.length>0){
            for (let i = 0; i < response.data.data.result.productImage.length; i++) {
              // text += cars[i] + "<br>";
              await this.getFoto(response.data.data.result.productImage[i].path_image)
              var data ={
                id: response.data.data.result.productImage[i].id,
                foto: this.imageShowPush,
                path:response.data.data.result.productImage[i].path_image,
              }
              this.itemsGaleri.push(data)
              if(i==0){this.fotoShow =this.imageShowPush}
            }
          }
        } else {
          console.log("Kosong");
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          console.log("Kosong");
          this.valueIsi = false;
        }
      }
    },
      
    async getFoto(val){
      // var imagesvalue = 'public/uploads/1656286341_login_tokomas.PNG';
      var imagesvalue = val;
      try {
        const response = await axios.get(this.BaseUrlApi + "getImagesWithoutAuth?path_file="+imagesvalue);
        if (response.data.length != 0) {
          this.imageShowPush = "data:image/jpg;base64," + response.data.data.result;
        } else {
          console.log("Kosong");
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
        }
      }
    },

    showImageClick(val){
      this.fotoShow = val
    },

    
    scroll(id) {  
      console.log('id->',id)
      document.getElementById(id).scrollIntoView({
        behavior: "smooth"
      })
    }

  },
};
</script>
<style scoped>
.v-main {
    background-color:#FFFFFF !important;
}

@media (min-width: 576px) {
  .container {
    max-width: 500px !important;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 700px !important;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 850px !important;
  }
}

@media (min-width: 1100px) {
  .container {
    max-width: 900px !important;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1000px !important;
  }
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 500px !important;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 700px !important;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 850px !important;
  }
}

@media (min-width: 1100px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 900px !important;;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1000px !important;;
  }
}


</style>