<template>
  <div>
    <v-breadcrumbs
      class="breadcrumbsmain fontall"
      :items="itemsbr"
      large
      color="black"
      style="font-weight: bold"
      ><template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template></v-breadcrumbs
    >

    <v-data-table
      :headers="headers"
      :items="dataobject"
      :search="search"
      :loading="loadtable"
      loading-text="Loading... Please wait"
      class="rounded elevation-6 mx-3 pa-1 itemchild"
    >

    <template #item.id_buku="{ value }">
        <a @click='showDetailModal(value)'>
          {{ value }}
        </a>
    </template>
    <template #item.link="{ value }">
        <a @click='showLink(value)'>
          {{ value }}
        </a>
    </template>
      <template v-slot:item.aktif="{ item }">
        {{ getStatusAktif(item.aktif) }}
      </template>

      <template v-slot:top>   
        <div class="mb-2 mt-1 mx-1" style="background-color:#d3dffd"><h2 class="pl-2 py-2">Daftar Barang</h2></div>     
        <v-divider class="mb-5 mt-1"></v-divider>
        <v-toolbar flat>
          <v-btn dark class="mb-2" @click="showAddModal()" color="primary">
            <v-icon class="mr-1" small>mdi-sticker-plus-outline</v-icon> Tambah
          </v-btn>
          <v-btn :loading="loading" dark class="mb-2 ml-2" @click="downloadPDF()" color="#25695c">
            <v-icon class="mr-1" small>mdi-download-circle-outline</v-icon> PDF
          </v-btn>
          <v-spacer></v-spacer>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search..."
            single-line
            hide-details
          ></v-text-field>
          <!-- Modal Add Edit -->
          <v-dialog persistent v-model="dialog" max-width="750px">
            <v-card>
              <v-card-text class="fontall pb-0 px-2 pt-3">
                <div class="mb-2 mt-1 mx-1" style="background-color:#d3dffd;display: flex">
                  <h2 class="pl-2 py-2">{{titlemodal}}</h2>
                <v-spacer></v-spacer>
                  <v-btn icon small class="ma-1 mr-2" @click="close">
                    <v-icon center> mdi-window-close </v-icon>
                  </v-btn>
                </div> 
              </v-card-text>
              <v-divider class="mt-2 mb-3 mx-3"></v-divider>
              <v-card-text class="fontall pb-1">
                <v-row>
                    <v-col class="pb-0" cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="defaultItem.nama_buku"
                        label="Nama Buku"
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>

                    <v-col class="pb-0" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="defaultItem.id_buku"
                        label="Kode Buku"
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-0" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="defaultItem.barcode"
                        label="Barcode"
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>

                    <v-col class="pb-0" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="defaultItem.penerbit"
                        label="Penerbit"
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-0" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="defaultItem.penulis"
                        label="Penulis"
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>

                    <v-col class="pb-0" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="defaultItem.isbn"
                        label="ISBN"
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-0" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="defaultItem.jenis_cover"
                        label="Jenis Cover"
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>

                    <v-col class="pb-0" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="defaultItem.ukuran"
                        label="Ukuran"
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-0" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="defaultItem.berat"
                        label="Berat (gr)"
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-0" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="defaultItem.halaman"
                        label="Halaman"
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>

                    <v-col class="pb-0" cols="12" sm="12" md="12">
                      <v-textarea
                        v-model="defaultItem.deskripsi"
                        label="Deskripsi"
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-textarea>
                    </v-col>
                  </v-row>
              </v-card-text> 
              <v-divider class="my-1 mx-3"></v-divider>
              <v-card-actions>
                <v-btn dark :loading="loading" color="#25695c" @click="save">
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon>
                  Simpan
                </v-btn>
                <v-btn dark color="#8b8787" @click="close">
                  <v-icon left> mdi-close-circle-outline </v-icon> Batal
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          
          <!-- Modal Edit Show -->
          <v-dialog persistent v-model="dialogEditShow" max-width="450px">
            <v-card>
              <v-card-text class="fontall pb-0 px-2 pt-3">
                <div class="mb-2 mt-1 mx-1" style="background-color:#d3dffd;display: flex">
                  <h2 class="pl-2 py-2">Pilih Edit</h2>
                <v-spacer></v-spacer>
                  <v-btn icon small class="ma-1 mr-2" @click="close">
                    <v-icon center> mdi-window-close </v-icon>
                  </v-btn>
                </div> 
              </v-card-text>
              <v-divider class="mt-2 mb-3 mx-3"></v-divider>
              <v-card-actions>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                     <v-btn block dark :loading="loading" color="#25695c" @click="editData">
                      <v-icon left> mdi-pencil </v-icon>
                      Edit Data Buku
                    </v-btn>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-btn block dark color="primary" @click="uploadImage">
                      <v-icon left> mdi-upload </v-icon> 
                      Upload Image
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Modal detail -->
          <v-dialog persistent v-model="dialogDetail" max-width="750px">
            <v-card>
              <v-card-text class="fontall pb-0 px-2 pt-3">
                <div class="mb-2 mt-1 mx-1" style="background-color:#d3dffd;display: flex">
                  <h2 class="pl-2 py-2">{{titlemodal}}</h2>
                <v-spacer></v-spacer>
                  <v-btn icon small class="ma-1 mr-2" @click="close">
                    <v-icon center> mdi-window-close </v-icon>
                  </v-btn>
                </div> 
              </v-card-text>
              <v-divider class="mt-2 mb-3 mx-3"></v-divider>
              <v-card-text class="fontall pb-1">
                <v-row>
                    <v-col class="pb-0" cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="defaultItem.link"
                        label="URL Detail "
                        dense
                        outlined
                        disabled
                        filled
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-0" cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="defaultItem.nama_buku"
                        label="Nama Buku"
                        dense
                        outlined
                        disabled
                        filled
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>

                    <v-col class="pb-0" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="defaultItem.id_buku"
                        label="Kode Buku"
                        dense
                        outlined
                        disabled
                        filled
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-0" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="defaultItem.barcode"
                        label="Barcode"
                        dense
                        outlined
                        disabled
                        filled
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>

                    <v-col class="pb-0" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="defaultItem.penerbit"
                        label="Penerbit"
                        dense
                        outlined
                        disabled
                        filled
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-0" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="defaultItem.penulis"
                        label="Penulis"
                        dense
                        outlined
                        disabled
                        filled
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>

                    <v-col class="pb-0" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="defaultItem.isbn"
                        label="ISBN"
                        dense
                        outlined
                        disabled
                        filled
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-0" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="defaultItem.jenis_cover"
                        label="Jenis Cover"
                        dense
                        outlined
                        disabled
                        filled
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>

                    <v-col class="pb-0" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="defaultItem.ukuran"
                        label="Ukuran"
                        dense
                        outlined
                        disabled
                        filled
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-0" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="defaultItem.berat"
                        label="Berat (gr)"
                        dense
                        outlined
                        disabled
                        filled
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-0" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="defaultItem.halaman"
                        label="Halaman"
                        dense
                        outlined
                        disabled
                        filled
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>

                    <v-col class="pb-0" cols="12" sm="12" md="12">
                      <!-- <v-textarea
                        v-model="defaultItem.deskripsi"
                        label="Deskripsi"
                        dense
                        outlined
                        disabled
                        filled
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-textarea> -->
                      <div filled class="mb-5" style="border-style: solid;border-color: lightgrey;background-color: lightgray;">
                        <h4>Deskripsi</h4>
                      <p style="text-align: left;margin-left: 20px;margin-right: 20px;font-size: 15px;">
                        <span v-html="defaultItem.deskripsi"></span>
                      </p>
                      </div>
                    </v-col>
                  </v-row>
              </v-card-text> 
            </v-card>
          </v-dialog>


          <!-- Modal Upload Image -->
          <v-dialog persistent v-model="dialogUploadImage" max-width="750px">
            <v-card>
              <v-card-text class="fontall pb-0 px-2 pt-3">
                <div class="mb-2 mt-1 mx-1" style="background-color:#d3dffd;display: flex">
                  <h2 class="pl-2 py-2">UploadImage</h2>
                <v-spacer></v-spacer>
                  <v-btn icon small class="ma-1 mr-2" @click="close">
                    <v-icon center> mdi-window-close </v-icon>
                  </v-btn>
                </div> 
              </v-card-text>
              <v-divider class="mt-2 mb-3 mx-3"></v-divider>
              <v-card-text class="fontall pb-1">
                <v-row>   
                  <v-col style="align-self: center;" cols="12" sm="6" md="6" class="py-0">
                        <v-file-input
                            class="pt-1 mt-0"
                            placeholder="Pilih Foto"
                            prepend-icon="mdi-file-document-multiple-outline"
                            outlined
                            dense
                            label = 'Pilih Foto'
                            v-model="file"
                            v-on:change="pilihfile"
                        ></v-file-input>
                        <div style="text-align: right">
                          <v-btn dark color="primary" @click="saveGaleri">
                            <v-icon left> mdi-upload </v-icon>
                            Upload
                          </v-btn>
                        </div>
                  </v-col>    
                  <v-col cols="12" sm="6" md="6">  
                                
                    <v-img
                      class="mx-2 my-2 pt-1"
                      contain
                      center
                      max-height="250"
                      v-bind:src="imageShow"
                    ></v-img>
                  </v-col>   
                </v-row>
                <v-divider class="mx-2 d-none d-md-block my-7" inset></v-divider>
                
                <v-row class="mb-1 mt-0">
                    <h4 style="color: black; font-size: 18px">
                      List Foto
                    </h4>
                  <v-divider
                      class="mx-2 mt-3"
                      style="border-color: black"
                  ></v-divider>
                </v-row>
                <v-row>                
                  <v-flex md6 v-for="item in itemsGaleri" :key="item.id">
                      <v-card height="320" class="pa-3 ma-2 center" >
                        <div style="text-align-last: center;height: 260px;">
                          <v-img v-bind:src="item.foto" contain center max-height="250"></v-img>
                        </div>   
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="error" small class="center" @click="deleteFoto(item.id)">
                            <v-icon>mdi-delete-outline</v-icon>Hapus Foto
                          </v-btn> 
                          <v-spacer></v-spacer>
                        </v-card-actions>              
                      </v-card>
                  </v-flex>
                </v-row>
              </v-card-text> 
              <v-divider class="my-1 mx-3"></v-divider>
            </v-card>
          </v-dialog>

          <!-- Modal Delete -->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >Apakah yakin menghapus buku ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn dark color="#25695c" @click="deleteItemConfirm">
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon>
                  OK</v-btn
                >
                <v-btn dark color="#8b8787" @click="closeDelete">
                  <v-icon left> mdi-close-circle-outline </v-icon>
                  Batal
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          
           <!-- Modal Delete Galeri-->
          <v-dialog v-model="dialogDeleteGaleri" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn dark color="red" @click="closeDelete">
                  <v-icon left> mdi-close-circle-outline </v-icon>
                  Cancel
                </v-btn>
                <v-btn dark color="#25695c" @click="deleteItemConfirmGaleri">
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon>
                  OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <!-- <v-icon class="mr-2" @click="detailItem(item)" color="primary">
          mdi-information
        </v-icon> -->
        <v-btn small class="mb-1 mt-1 mr-1" @click="showEditModal(item)" color="#1f8b24">
            <v-icon  color="white">mdi-lead-pencil</v-icon>
        </v-btn>
        <v-btn small class="mb-1 mt-1 mr-1" @click="showDeleteModal(item)" color="#E20404">
            <v-icon  color="white">mdi-delete-outline</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-snackbar
      v-model="snackbar"
      :color="colorsnackbar"
      :timeout="timeoutsnackbar"
    >
      {{ textsnackbar }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import HelperGlobal from "../../services/Helper";
const HelperGlobalService = new HelperGlobal();
import VueBarcode from 'vue-barcode';

export default {
  components: {
    'barcode': VueBarcode
  },
  name: "Barang",
  
  data: () => ({
    itemsbr: [
      {
        text: "Master Data",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
      {
        text: "Barang",
        disabled: true,
        href: "breadcrumbs_link_1",
      },
    ],
    barcodeValue: '123456789',
    rules: {
      required: (value) => !!value || "Field is Required.",
      countermin: (value) => value.length > 5 || "Min 6 characters",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
    rulesGambar: [
        value => !value || value.size < 2000000 || 'Foto size should be less than 2 MB!',
      ],
    loading: false,
    titlemodal: 'Tambah Admin Baru',
    isDisabledKode : true,
    isDisabledDetail: false,
    isDisabledKabKota: false,
    isShowSudahKeluar: false,
    isShowTanggalKeluar: false,
    isDelete: false,
    value: "add",
    dialog: false,
    dialogDelete: false,
    dialogReset: false,
    dialogEditShow: false,
    dialogUploadImage: false,
    loadtable: false,
    dialogDeleteGaleri: false,
    dialogDetail: false,
    search: "",
    authtoken: "",
    BaseUrlApi: "",
    BaseUrlShow: "",
    headers: [
      { text: "Kode", value: "id_buku", divider:true, width: "15%" },
      { text: "Nama Buku", value: "nama_buku", divider:true, width: "22%"  },
      { text: "ISBN", value: "isbn", divider:true  },
      { text: "URL Link Detail", value: "link", divider:true  },
      { text: "Actions", value: "actions", sortable: false, width: "12%", divider:true  },
    ],

    dataobject: [],

    defaultItem: {
      id_buku: "",
      barcode: "",
      nama_buku: "",
      deskripsi: "",
      penerbit: "",
      penulis: "",
      isbn: "",
      jenis_cover: "",
      halaman:'',
      ukuran: "",
      berat: "",
      link:"",
    },

    imagesResult:'-',
    imagesReturnBase64:'',
    imagesShow:'/images/noimage.png',
    imagesShow2:'/images/noimage.png',
    fileUpload:'',
    gambarinput:'',


    itemsGaleri:[],

    itemssupplier:[],
    itemsjenis: [],
    itemskadar: [],
    itemsstatus: [
      { text: "Aktif", value: 1 },
      { text: "Tidak_Aktif", value: 0 },
    ],

    AddModal: true,
    valid: true,

    snackbar: false,
    textsnackbar: "Test",
    timeoutsnackbar: 3000,
    colorsnackbar: null,


    aksesStatus : false,
    sudahKeluarStatus: false,
    
    role_code: "",

    typeValue : 'Add',

    
    fileUpload : '',
    fileUploadReady : false,
    file:'',
    imageShow: '/images/noimage.png',
    fotoUploadSave:'',

    imageShowPush:'/images/noimage.png',
    idGaleri:'',
  }),

  created() {
    // this.authtoken = localStorage.getItem("token");
    // this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
  },
  beforeMount() {    
    this.authtoken = localStorage.getItem("token");
    this.User = JSON.parse(localStorage.getItem("User"));
    if (this.User) {
      this.role_code = this.User.role_code;
    }
    // this.cekRoleAkses();
    // this.firstAccessPage();
    this.BaseUrlShow = HelperGlobalService.getBaseURLShow();
    this.BaseUrl = HelperGlobalService.getBaseURL();
    this.BaseUrlApi = HelperGlobalService.getBaseURLApi();
    this.initialize();
    // this.getKabKota();
  },

  methods: {
    cekRoleAkses(){
      // console.log(this.role_code)
      var roleCode = this.role_code;
      // console.log(roleCode.toString())
      switch (roleCode.toString()) {
        case '1':
          this.aksesStatus = true;
          this.headers = this.headersAdmin;
          // console.log(this.headers)
          break;
        case '2':
          this.aksesStatus = false;
          break;
        case '3':
          this.aksesStatus = false;
          break;
        case '4':
          this.aksesStatus = false;
          break;
      }
    },
    async initialize() {
      this.loadtable = true;
      try {
        const response = await axios.get(this.BaseUrlApi + "GetProduct", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.dataobject = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.dataobject = [];
        }
      this.loadtable = false;
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.dataobject = [];
        }
      this.loadtable = false;
      }
    },
    async getDetail() {
      try {
        const response = await axios.get(this.BaseUrlApi + "GetProductDetail/"+this.defaultItem.id_buku, {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.defaultItem = Object.assign({},response.data.data.result.data);
          // this.itemsGaleri = response.data.data.result.productImage;
          this.itemsGaleri = [];
          if(response.data.data.result.productImage.length>0){
            for (let i = 0; i < response.data.data.result.productImage.length; i++) {
              // text += cars[i] + "<br>";
              await this.getFoto(response.data.data.result.productImage[i].path_image)
              var data ={
                id: response.data.data.result.productImage[i].id,
                foto: this.imageShowPush,
                path:response.data.data.result.productImage[i].path_image,
              }
              this.itemsGaleri.push(data)
            }
          }

          console.log(this.itemsGaleri)
      
          // this.barcodeValue = this.defaultItem.kode_barang;
          // if(response.data.data.result.detail.foto_barang == '-'){
          //   this.imagesShow = '/images/noimage.png';
          // }else{
          //   await this.getFoto(response.data.data.result.detail.foto_barang);
          //   this.imagesShow = this.imagesReturnBase64;
          // }
        } else {
          console.log("Kosong");
          // this.itemskab = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          // this.itemskab = [];
        }
      }
    },
    async addApi(datapost) {
      try {
        const response = await axios.post(
          this.BaseUrlApi + "AddProduct",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        this.loading = false;
        if (response.data.data.result == "success") {
          this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses menambahkan data";
          this.initialize();
        } else {
          this.dialog = true;
        }
      } catch (error) {
        console.error(error);
        this.loading = false;
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        }
      }
    },
    async editApi(datapost) {
      console.log(datapost);
      try {
        const response = await axios.put(
          this.BaseUrlApi + "EditProduct/"+this.defaultItem.id_buku,
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        this.loading = false;
        if (response.data.data.result == "success") {
          this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses mengubah data";
          this.initialize();
        } else {
          this.dialog = true;
        }
      } catch (error) {
        console.error(error);
        this.loading = false;
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        }
      }
    },
    async deleteApi() {
      const datapost = {
        user_id: this.defaultItem.user_id,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.delete(
          this.BaseUrlApi + "HardDeleteProduct/"+this.defaultItem.id_buku,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses menghapus data";

          this.dialogDelete = false;
          this.dialog = false;
          this.initialize();
        } else {
          this.dialog = true;
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        }
      }
    },
    async deleteItemConfirmGaleri(){
      const datapost = {
        id: this.idGaleri,
        // no_induk_karyawan: this.deleteDetailItem.no_induk_karyawan,
      };
      // this.dialogDetail = false;
      try {
        const response = await axios.delete(
          this.BaseUrlApi + "HardDeleteProductImage/"+this.idGaleri,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses menghapus data";

          this.dialogDeleteGaleri = false;
          this.getDetail();
        } else {
          this.dialog = true;
        }
      } catch (error) {
        // console.error(error.response.data.data.result);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        }
        if (error.response.status == 400) {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = error.response.data.data.result;
        }
      }
    },
    
    async updateGaleri() {
      // this.dialogDetail = false;
      var datapost = {
        id_buku : this.defaultItem.id_buku,
        path_image : this.fotoUploadSave,
      }
      try {
        const response = await axios.post(
          this.BaseUrlApi + "AddProductImage",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          // this.dialog = false;
          // this.dialogEditTraining = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses tambah data";
          this.getDetail();
          // this.imageShow ='/images/noimage.png';
          this.fileUploadReady = false;
          this.file = '';
          this.fileUpload = '';
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal";
        }
      }
    },
    async uploadApi() {
      const formData = new FormData();
      formData.append("file", this.fileUpload);
      // console.log(formData);
      // this.dialogDetail = false;
      try {        
        const response = await axios.post(
          this.BaseUrlApi + "upload",
          formData,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.success);
        if (response.data.success == true) {
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses upload gambar";
          this.imagesResult = response.data.data.result;
          this.fotoUploadSave = response.data.data.result;
          // console.log(this.defaultItem.foto_barang);
          // this.dialogDelete = false;
          // this.dialog = false;
          // this.initialize();
        } else {
          this.dialog = true;
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        }
      }
    },    
    async getFoto(val){
      // var imagesvalue = 'public/uploads/1656286341_login_tokomas.PNG';
      var imagesvalue = val;
      try {
        const response = await axios.get(this.BaseUrlApi + "getimages?path_file="+imagesvalue, {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        if (response.data.length != 0) {
          this.imageShowPush = "data:image/jpg;base64," + response.data.data.result;
        } else {
          console.log("Kosong");
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
        }
      }
    },
    async downloadPDF(){
      console.log('error');  
      this.snackbar = true;
      this.colorsnackbar = "blue";
      this.textsnackbar = "Sedang Maintenance Fitur"; 
      // this.loading = true;
      // await axios({
      //     url: this.BaseUrlApi + "DownloadPDFBarang",
      //     method: 'GET',
      //     responseType: 'blob',
      //     headers: {Authorization: `Bearer ` + this.authtoken,"Content-Type": "application/json"},
      // }).then((response) => {
      //     var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      //     var fileLink = document.createElement('a');

      //     const d = Date.now();

      //     fileLink.href = fileURL;
      //     fileLink.setAttribute('download', 'fileBarang_'+d+'.pdf');
      //     document.body.appendChild(fileLink);

      //     fileLink.click();
          
      //   this.loading = false;

          
      // }).catch(function (error) {
      //   console.log(error.response.status);
      //   if (error.response.status == 401) {
      //     localStorage.removeItem("token");
      //     this.$router.push("/");
      //   } else {      
      //     console.log('error');  
      //     this.snackbar = true;
      //     this.colorsnackbar = "red";
      //     this.textsnackbar = "Gagal download";  
      //   }
        
      //   this.loading = false;
      // });
    },

    showAddModal() {
      this.defaultItem.id_buku = "";
      this.defaultItem.barcode = "";
      this.defaultItem.nama_buku = "";
      this.defaultItem.deskripsi = "";
      this.defaultItem.penerbit = "";
      this.defaultItem.penulis = "";
      this.defaultItem.isbn = "";
      this.defaultItem.jenis_cover = "";
      this.defaultItem.ukuran = "";
      this.defaultItem.berat = '';

      this.titlemodal = "Tambah Buku Baru";
      // console.log();
      console.log(this.$refs.form);
      if(this.$refs.form){
        this.$refs.form.resetValidation();
      }
      this.aksesStatus = false;
      this.AddModal = true;
      this.isDisabledDetail = false;
      this.isDisabledKabKota = true;
      this.isDelete = false;
      this.dialog = true;
      this.typeValue = 'Add';
    },
    showEditModal(item) {
      this.defaultItem.id_buku = item.id_buku;
      // console.log(item);
      this.titlemodal = "Ubah Data Buku";
      this.getDetail();
      // console.log(this.defaultItem);
      this.AddModal = false;
      this.isDisabledDetail = false;
      this.isDisabledKabKota = false;
      this.isDelete = false;
      this.dialogEditShow = true;
      this.typeValue = 'Edit';
    },
    editData(){
      this.dialogEditShow = false;
      this.dialog = true;
    },
    uploadImage(){
      this.dialogEditShow = false;
      this.dialogUploadImage = true;
    },
    

    pilihfile(event) {
      console.log(event);
      if (event != null) {
        this.fileUpload = event;
        this.fileUploadReady = true;
        this.imageShow = URL.createObjectURL(event);
      } else {
        this.fileUploadReady = false;
      }
    },
    deleteFoto(val){
      this.idGaleri = val;
      this.dialogDeleteGaleri = true;
    },


    showDeleteModal(item) {
      this.defaultItem.id_buku = item.id_buku;
      // console.log(item);
      // this.titlemodal = "Hapus Data Buku";
      // this.getDetail();
      // this.isDisabledDetail = true;
      // this.isDisabledKabKota = true;
      this.isDelete = true;
      this.dialogDelete = true;
    },
    showDetailModal(val){
      console.log(val)
      this.defaultItem.id_buku = val;
      this.getDetail();
      this.titlemodal = "Informasi Data Buku";
      this.dialogDetail = true;
      this.isDisabledDetail = true;
      this.isDisabledKabKota = true;
      this.isDelete = false;
    },
    showLink(val){
      console.log(val)
      window.open('https://'+val, '_blank');
    },

    // changeKota(){
    //   console.log(this.defaultItem.kabkota)
    // },
    pilihgambar(event) {
      console.log(event);
      if (event != null) {
        this.imagesShow = URL.createObjectURL(event);
        console.log(this.defaultItem.gambarshow);
        this.fileUpload = event;
        this.fileUploadReady = true;
        // this.filephotoarray.push("lahan1");
      } else {
        this.imagesShow = "/images/noimage.png";
        this.fileUploadReady = false;
      }
    },
    // deleteFoto(){
    //     this.imagesShow = "/images/noimage.png";
    //     this.fileUploadReady = false;
    //     this.gambarinput = "";
    //     this.defaultItem.foto_barang = '-';
    // },
    saveFoto(){
      if(this.fileUploadReady == true){
        this.uploadApi();
      }else{
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar =
          "Gambar kosong, tidak bisa upload gambar";
        this.loading = false;
      }
    },

    close() {
      this.dialog = false;
      this.dialogDetail = false;
      this.dialogReset = false;
      this.dialogEditShow = false;
      this.dialogUploadImage = false;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.dialogDeleteGaleri = false;
    },

    save() {
      this.loading = true;
      // console.log(window.location.href);
      // this.defaultItem.link = this.BaseUrlShow+'/#/DetailBuku/'+this.defaultItem.id_buku;
      this.defaultItem.link = this.BaseUrlShow+'/#/DetailBuku/'+this.defaultItem.nama_buku.split(' ').join('-');
      const datapost = this.defaultItem;
      
      if(this.defaultItem.deskripsi){
        const string = this.defaultItem.deskripsi;
        const result = string.replace(/(\r\n|\r|\n)/g, '<br>');

        console.log(result);
        this.defaultItem.deskripsi = result
      }
      
        console.log(datapost);

      if (HelperGlobalService.checkMandatory(datapost, "object") == true) {
        console.log(datapost);

        if (this.typeValue == 'Edit') {
          this.editApi(datapost);
        } else {
          this.addApi(datapost);
        }
        this.loading = false;
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar =
          "Gagal Simpan, Kolom required (*) tidak boleh ada yang kosong";
        this.loading = false;
      }
    },

    async saveGaleri() {

      if (this.fileUploadReady == true) {

        await this.uploadApi()
        this.updateGaleri();
        
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar =
          "Gagal Simpan, Foto kosong";
      }
    },

    deleteItemConfirm() {
      this.deleteApi();
    },

    resetItem(item) {
      this.defaultItem.user_id = item.user_id;
      this.defaultItem.email = item.email;
      this.dialogReset = true;
    },

    getColorStatus(role_code) {
      console.log(role_code);
      if (role_code == 0) return "#25695C";
      else if (role_code == 1) return "#25695C";
      else if (role_code == 2) return "#BF9168";
      else if (role_code == 3) return "#D42F2F";
      else return "#9CACA3";
    },
    getStatusAktif(val){
      if(val == 1){
        return 'Aktif';
      }else{
        return 'Tidak Aktif'
      }
    }
  },
};
</script>
<style>
table {
  border: 1px solid;
  border-color: lightgrey;
}

.v-data-table-header{
  background-color: #d3dffd;
}

input[required] + label:after {
	content: '*';
	color: red;
  }
</style>
